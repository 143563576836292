<script lang="ts" setup>
import { computed, ref } from 'vue'
import RoomieNavigationButton from '@lahaus-roomie/roomie/src/components/RoomieNavigationButton/index.vue'
import IconChevronLeft from '@lahaus-roomie/roomie/src/assets/icons/chevron-left.svg'
import IconChevronRight from '@lahaus-roomie/roomie/src/assets/icons/chevron-right.svg'
import IconBack from '@lahaus-roomie/roomie/src/assets/icons/v2/back.svg'
import NextIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/next.svg'
import type { Props } from './types'
import './_index.scss'
import { useThemeStore } from '@/stores/useThemeStore'

const themeStore = useThemeStore()

const props = withDefaults(defineProps<Props>(), {
  lateralPaddings: 'mobile',
  showButtons: false,
  sliderScroll: 100,
  lhId: '',
  navigationButtonSize: 'lg'
})

const sliderScrollLeft = ref(0)
const itemsHolder = ref()

const handleScroll = (event: Event) => {
  const target = event.target as HTMLElement
  const scrollAmount = target.scrollLeft
  sliderScrollLeft.value = scrollAmount
}

const showLeftButton = computed(() => {
  return props.showButtons && sliderScrollLeft.value > 0
})

const showRightButton = computed(() => {
  const totalWidth = itemsHolder.value?.scrollWidth
  const offsetWidth = itemsHolder.value?.offsetWidth

  if (totalWidth && offsetWidth) {
    const isAtEnd = totalWidth - sliderScrollLeft.value < offsetWidth + 10
    return props.showButtons && !isAtEnd
  }

  return false
})

const handleButtonClick = (scrollLeft: boolean) => {
  let newScrollLeft
  const amountToScoll = props.sliderScrollAuto ? itemsHolder.value.offsetWidth : props.sliderScroll

  if (scrollLeft) newScrollLeft = sliderScrollLeft.value - amountToScoll
  else newScrollLeft = sliderScrollLeft.value + amountToScoll
  sliderScrollLeft.value = newScrollLeft

  scrollSlider()
}

const scrollSlider = () => {
  itemsHolder.value!.scroll({
    behavior: 'smooth',
    left: sliderScrollLeft.value
  })
}
</script>

<script lang="ts">
export default {
  name: 'AppSlider'
}
</script>

<template>
  <div class="app-slider flex items-center relative">
    <RoomieNavigationButton
      v-if="themeStore.isV2"
      id="app-slider-left-btn"
      class="app-slider__button app-slider__button--left"
      :size="navigationButtonSize"
      variant="outlined"
      :class="{
        'invisible': !showLeftButton,
      }"
      :data-lh-id="`${id}-button-scroll-left`"
      aria-label="Atrás"
      @click="handleButtonClick(true)">
      <IconBack />
    </RoomieNavigationButton>

    <button
      v-else
      id="app-slider-left-btn"
      class="app-slider__button app-slider__button--left mr-8"
      :class="{
        'invisible': !showLeftButton,

        'w-32 h-32': !themeStore.isV2
      }"
      :data-lh-id="`${id}-button-scroll-left`"
      aria-label="Atrás"
      @click="handleButtonClick(true)">
      <span
        :class="{

          'w-32 h-32 fill-primary-600': !themeStore.isV2
        }"
        class="pointer-events-none">

        <IconChevronLeft />
      </span>
    </button>

    <div
      ref="itemsHolder"
      class="app-slider-items-holder overflow-x-auto scrollbar-hidden z-1"
      :data-lh-id="`${id}-items-holder`"
      @scroll="(event) => handleScroll(event)">
      <div class="app-slider__items-holder flex gap-12 md:gap-16">
        <span
          :class="{
            'block lg:hidden': lateralPaddings === 'mobile',
            'hidden md:block': lateralPaddings === 'desktop',
            'block': lateralPaddings === 'always',
            'hidden': lateralPaddings === 'hidden'
          }"
          class="app-slider__left-padding flex-none w-12" />

        <slot />

        <span
          :class="{
            'block md:hidden': lateralPaddings === 'mobile',
            'hidden md:block': lateralPaddings === 'desktop',
            'block': lateralPaddings === 'always',
            'hidden': lateralPaddings === 'hidden' && !paddingRight,
          }"
          class="app-slider__right-padding flex-none w-20 lg:w-16" />
      </div>
    </div>

    <div
      v-if="shadowHeight && showRightButton"
      class="app-slider-shadow shadow-[0_0_30px_30px_#fff] hidden lg:block z-[5]"
      :style="{ height: shadowHeight }" />

    <RoomieNavigationButton
      v-if="themeStore.isV2"
      id="app-slider-right-btn"
      :size="navigationButtonSize"
      variant="outlined"
      class="app-slider__button app-slider__button--right ml-8"
      :class="{
        'invisible': !showRightButton,
      }"
      :data-lh-id="`${id}-button-scroll-right`"
      aria-label="Siguiente"
      @click="handleButtonClick(false)">
      <NextIcon />
    </RoomieNavigationButton>

    <button
      v-else
      id="app-slider-right-btn"
      class="app-slider__button app-slider__button--right ml-8"
      :class="{
        'invisible': !showRightButton,
        'w-32 h-32': !themeStore.isV2
      }"
      :data-lh-id="`${id}-button-scroll-right`"
      aria-label="Siguiente"
      @click="handleButtonClick(false)">
      <span
        :class="{
          'w-32 h-32 fill-primary-600': !themeStore.isV2
        }"
        class="pointer-events-none">

        <IconChevronRight />
      </span>
    </button>
  </div>
</template>
